import React, {useRef, useState} from 'react';
import axios from 'axios'
import logo from './images/logo.png';
import './App.scss';
import './contracts.scss';
import {FormBuilder, formData} from "./components/client-form";

import {contract, Contract, customer} from "./components/contract";
import {Page,Document} from "react-pdf";

export interface invoice {
    customer: customer,
    order: order[],
    date:number,
    expirationDate:number,
    number:number,
    paid:boolean,
}

export interface order {
    product: string,
    quantity: number,
    description:string,
}

export interface product {
    name:string,
    price:number,
    description:string,
    data:any
}

let mainEndPoint = 'https://europe-west2-lobesoftwebsite.cloudfunctions.net/apiBuy';
let docEndPoint = 'https://europe-west2-lobesoftwebsite.cloudfunctions.net/docGen';
const storageKey = 'buy-lobesoft-app';
/*let mainEndPoint = 'http://localhost:5001/lobesoftwebsite/europe-west2/apiBuy';
let docEndPoint = 'http://localhost:5001/lobesoftwebsite/europe-west2/docGen';*/
let endpoints = {
  clients:'/clients',
  contract:'/contract',
  client:'/clientsID',
  contractIndex:'/contractIndex',
    invoiceIndex:'/invoiceIndex',
    invoice:'/invoice',
    products:'/products',
};

function UTCdate() {
    return Math.floor(Date.now() / 1000)
}

function addTime(startDate:number,[years = 0,days = 0,hours = 0,minutes = 0,seconds = 0]){
    years = years * ((3600 * 24) * 365);
    days = days * (3600 * 24);
    hours = hours * 3600;
    minutes = minutes * 60;
    return startDate = startDate + years + days + hours + minutes + seconds;
}

async function getDataAxios(endPoint:string,options?:any){
  const response =
      await axios.get(endPoint,options);
  console.log('GET',response);
  return response;
}

async function postDataAxios(endPoint:string,data:any,options?:any){
  const response = await axios.post(endPoint,data,options);
  console.log('POST:',response);
  return response;
}

async function updatetDataAxios(endPoint:string,data:any,options?:any){
    const response = await axios.patch(endPoint,data,options);
    console.log('POST:',response);
    return response;
}

async function getClients() {
  let options = {headers:{Authorization:'7867298340947'}};
  console.log(await  getDataAxios(mainEndPoint+endpoints.clients,options))
}

export async function getInvoiceIndex() {
    let options = {headers:{Authorization:'7867298340947'}};
    let res = await getDataAxios(mainEndPoint+endpoints.invoiceIndex,options);
    return res;
}

export async function getContractIndex() {
    let options = {headers:{Authorization:'7867298340947'}};
    let res = await getDataAxios(mainEndPoint+endpoints.contractIndex,options);
    return res;
}

async function setContractIndex() {
    let options = {headers:{Authorization:'7867298340947'}};
   let result = await postDataAxios(mainEndPoint+endpoints.contractIndex,null,options);
   return result;
}

async function setInvoiceIndex() {
    let options = {headers:{Authorization:'7867298340947'}};
    let result = await postDataAxios(mainEndPoint+endpoints.invoiceIndex,null,options);
    return result;
}

async function setProduct(data:any) {
    let options = {headers:{Authorization:'7867298340947'}};
    let result = await postDataAxios(mainEndPoint+endpoints.products,data,options);
    return result;
}

function getContractPDF(id:any) {
    let options = {headers:{Authorization:'7867298340947','Accept': 'application/pdf'},responseType: 'arraybuffer'};
    return getDataAxios(docEndPoint+endpoints.contract+`?id=${id}`,options);
}

function getInvoicePDF(id:any) {
    let options = {headers:{Authorization:'7867298340947','Accept': 'application/pdf'},responseType: 'arraybuffer'};
    return getDataAxios(docEndPoint+endpoints.invoice+`?id=${id}`,options);
}

async function addClient(data:customer) {
  let options = {headers:{Authorization:'7867298340947'}};
  let res = await postDataAxios(mainEndPoint+endpoints.client+`?id=${data.companyID}`,data,options);
  return res;
}

async function addContract(data:contract) {
    let options = {headers:{Authorization:'7867298340947'}};
    let res = await postDataAxios(mainEndPoint+endpoints.contract,data,options);
    return res;
}

async function addInvoice(data:any,id:number) {
    console.log('ADDING INVOICE');
    let options = {headers:{Authorization:'7867298340947'}};
    let res = await postDataAxios(mainEndPoint+endpoints.invoice+`?id=${id}`,data,options);
    return res;
}

async function updateInvoice(id:any) {
    console.log('UPDATE INVOICE');
    let options = {headers:{Authorization:'7867298340947'}};
    let res = await updatetDataAxios(mainEndPoint+endpoints.invoice+`?id=${id}`,null,options);
    return res;
}

async function updateInvoiceIndex() {
    console.log('UPDATE INVOICE INDEX');
    let options = {headers:{Authorization:'7867298340947'}};
    let res = await postDataAxios(mainEndPoint+endpoints.invoiceIndex,null,options);
    return res;
}

async function getInvoice(id:number) {
    console.log('ADDING INVOICE');
    let options = {headers:{Authorization:'7867298340947'}};
    let res = await getDataAxios(mainEndPoint+endpoints.invoice+`?id=${id}`,options);
    return res;
}

function readLocalStorage(key:string) {
    let timeStamp = Math.floor(Date.now() / 1000);
    let storage = window.localStorage;
    let bundle:any = storage.getItem(key);
    bundle = JSON.parse(bundle);

    if(bundle){
        if(bundle.exipiration === -1){
            return bundle.data;
        } else {
            if((bundle.timeStamp+bundle.exipiration - timeStamp) <= 0) {
                removeLocalStorage(storageKey);
                return null;
            } else {
                console.log('NOT EXPIRED');
                return bundle.data;
            }
        }
    } else {
        return bundle
    }
}

function saveTolocalStorage(key:string,data:any,exipiration:number = -1) {
    let storage = window.localStorage;
    let bundle = {
        data,
        timeStamp: Math.floor(Date.now() / 1000),
        exipiration
    };
    storage.setItem(storageKey,JSON.stringify(bundle))
}

function removeLocalStorage(key:string) {
    let storage = window.localStorage;
    storage.removeItem(key);
}

const FormData:formData = { sections: [
      {
        title:'Dati Responsabile',
        fields: [
          {
            type:'text',
            label:'Nome',
            name:'name',
          },
          {
            type:'text',
            label:'Cognome',
            name:'surname',
          },
            {
                type:'text',
                label:'Ruolo',
                name:'role',
            },
          {
            type:'email',
            label:'Email',
            name:'email',
          },
          {
            type:'text',
            label:'Telefono',
            name:'phone',
          },
        ]
      },
        {
          title:'Dati Azienda',
              fields: [
          {
            type:'text',
            label:'Ragione Sociale',
            name:'companyName',
          },
          {
              type:'text',
              label:'Telefono Azienda',
              name:'companyPhone',
          },
          {
              type:'text',
              label:'Mail Azienda',
              name:'companyEmail',
          },
          {
            type:'text',
            label:'Indirizzo Sede Legale',
            name:'address',
          },
          {
            type:'text',
            label:'Partita IVA',
            name:'companyID',
          },
              ]
        }
],
style: 'clientForm'
};

export interface acceptContract {
  customer: customer,
  onAccept: any,
  contractIndex :number
}

const Footer:React.FC = () => {
  return (
      <div className='wide flex align-cen justCon-cen row marTop'>
          <img src={logo} className='logo'/>
          <p className='logoType'>LOBESOFT</p>
      </div>
  )
};

const Spinner: React.FC = () => {
  return (
      <div className='overLay'>
        <div className='spinner'>
          <svg  viewBox="0 0 24 30"
                version="1.1" x="0px" y="0px"><title>icon/loading-spinner-solid</title>
            <desc>Created with Sketch.</desc>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <path
                  d="M12,23 C5.935,23 1,18.065 1,12 C1,8.086 3.106,4.436 6.498,2.473 L7.5,4.203 C4.725,5.81 3,8.797 3,12 C3,16.963 7.037,21 12,21 C16.963,21 21,16.963 21,12 C21,7.037 16.963,3 12,3 L12,1 C18.065,1 23,5.935 23,12 C23,18.065 18.065,23 12,23"
                  fill="#000000"/>
            </g>
          </svg>
        </div>
      </div>
  )
};

const MiniSpinner: React.FC = () => {
    return (
            <div className='miniSpinner'>
                <svg  viewBox="0 0 24 30"
                      version="1.1" x="0px" y="0px"><title>icon/loading-spinner-solid</title>
                    <desc>Created with Sketch.</desc>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <path
                            d="M12,23 C5.935,23 1,18.065 1,12 C1,8.086 3.106,4.436 6.498,2.473 L7.5,4.203 C4.725,5.81 3,8.797 3,12 C3,16.963 7.037,21 12,21 C16.963,21 21,16.963 21,12 C21,7.037 16.963,3 12,3 L12,1 C18.065,1 23,5.935 23,12 C23,18.065 18.065,23 12,23"
                            fill="#000000"/>
                    </g>
                </svg>
            </div>
    )
};

const ContractAcceptance: React.FC<acceptContract> = ({customer, onAccept, contractIndex}) => {

    const [state,setState] = useState({
        loading:false,
        error:'',
    });

    const timeStamp = Math.floor(Date.now() / 1000);
   const onDownload = () => {

       setState({...state,loading: true});
       getContractPDF(customer.companyID).then((data) => {
           console.log('PDF',data);
           const blob = new Blob([data.data], {type: 'application/pdf'});
           const link = document.createElement('a');
           link.href = window.URL.createObjectURL(blob);
           link.download = `Contratto-Lobesoft.pdf`;
           link.click();
           document.removeChild(link);
           setState({...state,loading: false});
       }).catch((e) => {
           setState({...state,loading: false});
            console.log('ERROR PDF',e);
       })
   };

   const onAccepContract = () => {
       let data:contract = {
           customer: customer.companyID,
           date: timeStamp,
           product: {
               name: 'Pacchetto Contabilitá Standard',
               price: 1399,
               description: '',
               data:{
                   dayOperations:60
               }
           },
           index:contractIndex+1,
       };
       onAccept(data);
   };

  return (
        <div className='packageInfo'>
          <h1 className='titleBar'>
           Accettazione Contratto
          </h1>
          <div className='contractBox'>
            <Contract customer={customer} date={timeStamp} index={contractIndex+1} product={'Pacchetto Contabilitá Standard'}/>
          </div>
          <div onClick={onDownload} className='buttonBlue flex'>
            Scarica in formato PDF {state.loading && <MiniSpinner/>}
          </div>
          <div className='wide buttonBlue marTop' onClick={onAccepContract}>
            ACCETTO E SOTTOSCRIVO CONTRATTO
          </div>
        </div>
  )
};

const PackageInfo: React.FC<featurList> = (props: featurList) => {

  const [state,setState] = useState({
      collapsed:true,
      loading:false,
  });

  let endedTransaction = false;

  const onDownload = () => {
      setState({...state, loading: true});
      getInvoicePDF(props.invoice).then((data) => {
          console.log('PDF',data);
          const blob:any = new Blob([data.data], {type: 'application/pdf'});
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `Fattura-Lobesoft.pdf`;
          link.click();
          document.removeChild(link);
          setState({...state, loading: false});
      }).catch((e) => {
          setState({...state, loading: false});
          console.log('ERROR PDF',e);
      })
  };

  const toggleCollapse = () => {
    setState({...state, collapsed: !state.collapsed})
  };

  let btnStyle = {
      marginBottom: state.collapsed ? '50px' : '0px'
  };

  return (
      <div className='packageInfo'>
        <h1 className='titleBar'>
          Il Tuo Ordine
        </h1>
        <h2>
            1 x Pacchetto contabilità “STANDARD”
        </h2>
        {
          props.list.map((prop, index) => {
            return <Feature name={prop.name} value={prop.value}/>
          })}
        <h2>
          Metodi di pagamento
        </h2>
          <div id='paypallCheckout'/>
        <div className='buttonCheckout' style={btnStyle} onClick={toggleCollapse} >
          BONIFICO BANCARIO
        </div>
        <div className={`collapsedDiv flex justCon-cen txt-left ${state.collapsed ? '': 'showDiv'}`}>
          <div className='bankBox'>
              <div style={{padding:'1em'}}>
                  Nome banca: Bank of Georgia<br/>
                  Indirizzo banca: 2 Leonidze Street, Tbilisi,
                  Georgia<br/>
                  Beneficiario: LOBESOFT LLC<br/>
                  IBAN: GE65BG0000000131436677<br/>
                  Swift code: BAGAGE22 XXX<br/>
              </div>

              <div onClick={onDownload} className='buttonBlue justCon-cen flex'>
                  Scarica PDF Fattura {state.loading && <MiniSpinner/>}
              </div>
          </div>
        </div>
      </div>
  )
};

const TransactionComplete: React.FC<transactionComplete> = (props:transactionComplete) => {

  const [state,setState] = useState({
    loading:false,
  });

  const onDownload = () => {
      setState({...state, loading: true});
      getInvoicePDF(props.invoice).then((data) => {
          console.log('PDF',data);
          const blob:any = new Blob([data.data], {type: 'application/pdf'});
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `Fattura-Lobesoft.pdf`;
          link.click();
          document.removeChild(link);
          setState({...state, loading: false});
      }).catch((e) => {
          setState({...state, loading: false});
          console.log('ERROR PDF',e);
      })
  };

  return (
      <div className='packageInfo'>
        <h1 className='titleBar'>
          {props.title}
        </h1>
        <div className='flex column'>
          <h3 className='small'>Resoconto Ordine:</h3>
          <h2>
            1 x Pacchetto Contabilitá "Standard"
          </h2>
        </div>
        <h3 className='text'>{props.message}</h3>
          {!props.error &&
          <div className='bankBox'>
              <div className='buttonBlue flex' onClick={onDownload}>
                  Scarica PDF Fattura {state.loading && <MiniSpinner/>}
              </div>
          </div>}
      </div>
  )
};

interface feature {
  name: string,
  value: string,
}

interface transactionComplete {
  title:string,
  message:string,
  invoice:any,
  error:boolean,
}

interface featurList {
  list: feature[],
  status: string,
  customer: customer,
  collapsed:true,
  invoice:any
}

const Feature: React.FunctionComponent<feature> = (props: feature) => {
  return (
      <div className='feature'>
        <p className='label'>{props.name}</p>
        <p>{props.value}</p>
      </div>
  )
};

const App: React.FC = () => {

    document.title = 'Lobesoft Checkout';

  const [data, setData] = useState(
      {
        features: [{
          name: 'Operazioni Giornaliere',
          value: '60',
        },
          {
            name: 'Prezzo Mensile',
            value: '1399,00€',
          }],
        status: 'form',
        loading: false,
          index: 0,
        customer: null,
        invoice:undefined,
      }
  );

  const [greeting, setGreeting] = useState(
      {
        message: 'ciao',
        title: 'ciao',
        error:false,
      }
  );

  const [customer,setCustomer] = useState(
      {
        name: '',
        surname: '',
        email: '',
        phone: '',
        companyName: '',
        address: '',
        companyID: '',
         role: '',
      }
  );

  const [formError,setFormError] = useState(
      {error:undefined}
  );

  let readedState = useRef(false);
  let readedCustomer = useRef(false);
  let readedGreeting = useRef(false);


    let localStatus = readLocalStorage(storageKey);
    console.log(localStatus);
    if(localStatus && data.status === 'form') {
        let newData = localStatus.data;
        let newCustomer = localStatus.customer;
        let newGreeting = localStatus.greeting;
        console.log('USE REF',readedCustomer.current,readedCustomer);
        if(!readedState.current && newData) {
            console.log('GETTIG LOCAL DATA');
            readedState.current = true;
            setData(newData);
        }
        if(!readedCustomer.current && newCustomer) {
            console.log('GETTING LOCAL CUSTOMER');
            readedCustomer.current = true;
            setCustomer(newCustomer)
        }
        if(!readedGreeting.current && newGreeting) {
            console.log('GETTING LOCAL CUSTOMER');
            readedCustomer.current = true;
            setGreeting(newGreeting)
        }
    }

  console.log('new app');  console.log(data);

  let greetComponent = <TransactionComplete message={greeting.message} title={greeting.title} invoice={data.invoice} error={greeting.error}/>;

  if(data.status === 'checkout') {
    let p = setupPaypal({
      clientid: 'AfkYw6fJC77aLJflYUWRuVkXZdUAUTlJ6O_4UdeoIXHtQuOoBYGgOotK9heJTCqkyVJ22fM9IFslzLzW'
    });
    p({
      currency: 'EUR',
      amount: 1399,
      rendertag: '#paypallCheckout',
    }).then(async (ok) => {
      let newData = {...data};
      setData({...data,loading: true});
      try {
          await updateInvoice(data.invoice);
      } catch (e) {
          setData({...data,loading: false});
      }
      newData.status = 'greeting';
      setData(newData);
      let newgreet = {...greeting};
      newgreet.title = 'Transazione Completata';
      newgreet.message = 'la transazione é stata portata a termine con successo';
      setGreeting(newgreet);
      saveTolocalStorage(storageKey,{data:newData,customer:customer,greeting:newgreet},3600);
      //payment received
      console.log(ok);
    }).catch((err) => {
      let newData = {...data};
      newData.status = 'greeting';
      setData(newData);

      let newgreet = {...greeting};
      newgreet.title = 'Errore Transazione';
      newgreet.message = 'é avvenuto un errore durante la transazione, ti preghiamo di riprovare.';
      newgreet.error = true;
      setGreeting(newgreet);
      //error rx payment
      console.log('PAYPALL ERROR',err);
    });
  }

  let onFormSubmit = async (formData:any) => {
    console.log('adding customer',data);
    setData({...data, loading:true});
    /*setTimeout(()=>{
      let newData =  {...data, loading:false, status: 'contract'};
      let newCustomer = {...formData};
      setData(newData);
      setCustomer(newCustomer);
    },2000);*/
      let res:any;
      let index:any;
    try {
         res = await addClient(formData);
         index = await getContractIndex();
    } catch (e) {
        setData({...data, loading:false});
        // @ts-ignore
        setFormError({error: 'é avvenuto un errore si prega si riprovare'});
        return;
    }

    setData({...data, loading:false});

    if(res.status === 200) {
        let newData = {...data, status: 'contract', index: index.data.index};
        let newCustomer = {...formData};
      setData(newData);
        saveTolocalStorage(storageKey,{data:newData,customer:newCustomer},3600);
      setCustomer({...formData});
    }
  };

  let onAcceptContract = async (value:contract) => {
      let invoice:any;
      setData({...data, loading:true});
      try {
          await addContract(value);
          await setContractIndex();
          await setInvoiceIndex();
          invoice = await createInvoice();
          await setInvoiceIndex();
      } catch (e) {
          console.log(e);
          setData({...data, loading:false});
      }

    setData({...data, status: 'checkout' ,loading:false,invoice: invoice});
        console.log('CREATING NEW INVOICE');
      saveTolocalStorage(storageKey,{data:{...data, status: 'checkout', invoice:invoice},customer:customer},3600);
  };

  let onSetProduct = async () => {
    let prod = {
            name: 'Pacchetto Contabilitá Standard',
            price: 1399,
            description: '',
            data: {
                dailyOperations: 60
            }
        };
    await setProduct(prod)
  };

  let createInvoice = async () => {
      let customerID:any = customer.companyID;
      let product:any = {};
      let number = await getInvoiceIndex();
      let date = new Date();
      let id = Number(date.getMonth().toString() + date.getFullYear().toString() + (number.data.index + 100).toString());
      console.log('INVOICE ID',date.getMonth().toString(),date.getFullYear().toString());
      console.log('INVOICE ID',id);
      console.log('CUSTOMER',data.customer);

    let newInvoice:invoice = {
        customer: customerID,
        paid:false,
        date: UTCdate(),
        number: number.data.index + 100,
        expirationDate: addTime(UTCdate(),[0,30]),
        order: [{
            product: 'kj2pmY53L1wUA5Hp85WR',
            description: `Start of data collection and provision of accounting services. Total of 60 daily accounting operations for 22 days a month`,
            quantity: 1,
        }]
    };

   await addInvoice(newInvoice,id);
   console.log('NEW INVOICE',await getInvoice(id));
   return id;
  };

  let statusComponent = () => {
    switch (data.status) {
      case "form":
          return (
            <div className="App flex wide justCon-cen">
              {data.loading &&
              <Spinner/>}
              <FormBuilder data={FormData} onSubmit={onFormSubmit} error={formError.error}/>
            </div>

        );

      case "contract":
        return (
            <div className="App flex wide column justCon-cen">
              {data.loading &&
              <Spinner/>}
              <ContractAcceptance customer={customer} onAccept={onAcceptContract} contractIndex={data.index}/>
            </div>
        );


      case "checkout":
        return (
            <div className="App flex wide column justCon-cen">
              {data.loading &&
              <Spinner/>}
              <PackageInfo list={data.features} status={data.status} invoice={data.invoice} customer={customer} collapsed={true}/>
            </div>
        );

      case "greeting":
        return (
            <div className="App  flex wide justCon-cen">
              {greetComponent}
            </div>
        );
    }
  };



  return (
      <React.Fragment>
        {statusComponent()}
        <Footer/>
      </React.Fragment>
  )

  /*if(!data.greeting) {
    return (
        <div className="App">
          <PackageInfo list={data.features}/>
          <button onClick={getClients}>get clients</button>
        </div>
    );
  } else {
    return (
        <div className="App">
          {greetComponent}
        </div>
    );
  } */
};

interface pdfrenderer {
    id:any
    file:any
}

const PDFrenderer:React.FC<pdfrenderer> = (props:pdfrenderer) => {

    const [state,setState] = useState({
        file: undefined,
    });

    let getData = async () => {
        getInvoicePDF(props.id).then((data) => {
            console.log('PDF',data);
            const blob:any = new Blob([data.data], {type: 'application/pdf'});
            setState({...state, file: blob});
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `Fattura-Lobesoft.pdf`;
            link.click();
            document.removeChild(link);
        }).catch((e) => {
            console.log('ERROR PDF',e);
        })
    };

    if(!state.file) {
        getData().catch((e) => {
            console.log(e);
        })
    }

    if(state.file) {
        // @ts-ignore
        return (
            <div>
                <Document  file={props.file}>
                    <Page pageNumber={1} />
                </Document>
            </div>

        )
    } else {
        return (
            <Spinner/>
            )

    }

};

//extend window
declare global {
  interface Window {
    paypal: any;
    paypalsetuped: any
  }
}

interface argpaypal {
  clientid?: string,
}

interface paypalconfig {
  amount: number,
  currency: string,
  rendertag?: string,
}

function setupPaypal(arg: argpaypal): (product: paypalconfig) => Promise<any> {
  let listbutton: any = [];
  let f = (product: paypalconfig): Promise<any> => {
    if (product.rendertag == null) product.rendertag = '#root';
    let prom = new Promise<any>((resolve, reject) => {
      const s = document.createElement('script');
      if (window.paypalsetuped == null)
        s.src = `https://www.paypal.com/sdk/js?client-id=${arg.clientid}&currency=${product.currency}&intent=capture`;
      let createb = () => {
        //console.log('button');
        window.paypal.Buttons({
          createOrder: function (data: any, actions: any) {
            // Set up the transaction
            return actions.order.create({
              purchase_units: [{
                amount: {
                  value: product.amount
                }
              }]
            });
          },
          onApprove: function (data: any, actions: any) {
            // Capture the funds from the transaction
            return actions.order.capture().then(function (details: any) {
              resolve(details);
            });
          },
          onError: function (err: any) {
            // Show an error page here, when an error occurs
            reject(err);
          }
        }).render(product.rendertag);
      };
      s.onload = () => {
        window.paypalsetuped = true;
        for (let item of listbutton) {
          item();
        }
        listbutton = [];
      };
      if (window.paypalsetuped == null)
        document.head.appendChild(s);
      if (window.paypalsetuped == true)
        createb();
      else
        listbutton.push(createb);
      window.paypalsetuped = false;
    });
    return prom;
  };
  return f;
}

//start
/*let p = setupPaypal({
  clientid: 'AfkYw6fJC77aLJflYUWRuVkXZdUAUTlJ6O_4UdeoIXHtQuOoBYGgOotK9heJTCqkyVJ22fM9IFslzLzW'
});
p({
  currency: 'EUR',
  amount: 0.01,
  rendertag: '#paypallCheckout',
}).then((ok) => {
  //payment received
  console.log(ok);
}).catch((err) => {
  //error rx payment
  console.log(err);
});*/
/*p({
  currency: 'EUR',
  amount: 9000,
  rendertag:'#paypallCheckout'
}).then((ok) => {
  //payment received
  console.log(ok);
}).catch((err) => {
  //error rx payment
  console.log(err);
}); */

export default App;
