import React from 'react';
import logo from '../images/logo.png';
import firma from '../images/firma.png';
import {PDFDownloadLink, Document, Page, PDFViewer} from '@react-pdf/renderer'
import {getContractIndex} from "../App";

export interface customer {
    name: string
    surname: string
    email: string
    phone: string
    companyName: string
    address: string
    companyID: string
    role:string
}

export interface contractComp {
    date:number,
    customer:customer,
    product:any,
    index:number,
}

export interface contract {
    date:number,
    customer:string,
    product:any,
    index:number,
}

const timeStamp = Math.floor(Date.now() / 1000);
const date = new Date(timeStamp*1000).toLocaleDateString('it-IT');
const service = 'Pacchetto Standard';

export const Contract: React.FC<contractComp> = (props: contractComp) => {

    console.log(props);

    return (
        <div className='contract'>
            <div>
                <br/>
                <header className="clearfix">
                    <div id="logo">
                        <img src={logo}/>
                    </div>
                    <div id="company">
                        <h3 className="props.customer.name">Contratto tra {props.customer.companyName} e Lobesoft LLC</h3>
                        <h3 className="number">Contratto n° {props.index} del {date}</h3>
                    </div>
                </header>

                <div id="information"></div>
                <h1 className="information"><strong> Informazioni</strong></h1>
                <p> Il presente documento è stato realizzato da Lobesoft LLC.</p>
                <p><br/><strong> Contatti</strong></p>
                <br/>Contatto per la gestione del progetto:
                <br/>Cristian Pietrobon - CEO
                <br/>Telefono: +1 786 453 0818
                <br/>Email: info@lobesoft.com

                <div id="object"></div>
                <h1 className="object"> Oggetto del contratto</h1>
                <h2>Descrizione</h2>
                <p>L’oggetto del presente contratto è l’erogazione dei servizi contabili
                    quali: prima nota cassa/banca, fatturazione attiva, fatturazione passiva, liquidazione iva, esterometro,
                    registrazione e pagamento stipendi, pagamento fatturazione e utenze, riconciliazione bancaria, controllo
                    ricezione fatture elettroniche attraverso cassetto fiscale Entratel. <br/></p>

                <div id="step"></div>
                <h1 className="step"> Fasi del progetto</h1>
                <p>Fasi di realizzazione:<br/>
                    1- Raccolta dati specifici per procedere con l’integrazione Bridge.<br/>
                    Questa fase di raccolta dati specifici durerà un massimo di 60 giorni
                    da calendario.<br/>
                    2- Erogazione al 100% dei servizi contabili sopra descritti.<br/></p>

                <h2>Richiesta di conferma</h2>
                <p>{props.customer.companyName} deve confermare la richiesta di procedere con l’erogazione dei
                    servizi sopracitati a Lobesoft LLC in questo modo:<br/>
                    <br/> - accettando il presente documento, in formato elettronico, <br/>
                    - pagando attraverso il sito web all'indirizzo buy.lobesoft.com,
                    oppure attraverso bonifico bancario (specifiche alla sezione “Termini
                    di pagamento”).</p>

                <h2>Richiesta di modifiche</h2>
                <p>Nessun cambiamento nei servizi forniti di seguito verrà eseguito fino alla
                    firma di una modifica a questo contratto da entrambe le parti; purché nulla
                    nel presente documento sollevi {props.customer.companyName} dall'obbligo di pagare
                    Lobesoft LLC per i servizi resi, che sono stati richiesti da {props.customer.companyName},
                    ma che non sono documentati in una modifica o nell'ambito di applicazione
                    applicabile.
                </p>


                <div id="price"></div>
                <h1 className="price"> Prezzo</h1>
                <br/> Il prezzo mensile per il numero di 60 operazioni massime giornaliere, per 5
                giorni a settimana, è di 1399,00€, da pagare al momento dell'accettazione
                del seguente contratto.
                <br/> La seconda mensilità avrà luogo a 60 giorni dal versamento della prima mensilità, al momento
                dell’erogazione dei servizi contabili sopra descritti.
                <br/>Le mensilità avranno una cadenza di 30 giorni, come da calendario
                solare.
                <br/>Qualunque modifica futura o la richiesta di operazioni
                aggiuntive si potrà acquistare dal pannello di controllo
                all'indirizzo web app.lobesoft.com, che sarà attivato dopo
                l'integrazione Bridge in un tempo massimo di 5 giorni
                lavorativi.<br/>

                <h2>Operazioni</h2>
                <p>Per approfondire il tariffario delle operazioni seguire il seguente link: <a href="https://lobesoft.com/tariffario/">vai al tariffario</a>.<br/>
                    Possono essere soggette a modifiche.</p>


                    <h2>Assistenza</h2>
                    <p>Ogni richiesta di assistenza pervenuta tramite: e-mail
                        (info@lobesoft.com), indirizzo web lobesoft.com nella
                        sezione aiuto, indirizzo web attraverso il pulsante:
                        chiamaci gratis, telefono +1 786 453 0818, riceverà una
                        risposta entro 24 ore. Specifichiamo che la risoluzione al
                        problema e le tempistiche dipendono dalla gravità del
                        problema e della richiesta. La tempistica di risoluzione
                        sarà comunicata al primo contatto (entro le 24 ore).</p>

                    <h2>Accordo legale</h2>
                    <p>Lobesoft LLC è obbligata a realizzare tutte le fasi del
                        presente contratto. <br/> Lobesoft LLC non è obbligata per
                        quanto riguarda nuovi cambiamenti o disposizioni che non
                        sono previsti nel seguente contratto.</p>

                    <h2>Fatturazione</h2>
                    - La fattura relativa alla raccolta dati ed all’erogazione dei
                    servizi contabili sopra citati è da pagare in una sola fase, al
                    100% all'accettazione del presente contratto.<br/>
                    La fattura segue l'accettazione del presente contratto e la
                    potrà scaricare cliccando il tasto SCARICA al momento del
                    pagamento avvenuto con Paypal al sito web buy.lobesoft.com.
                    In caso di pagamento con bonifico potrà scaricarla cliccando
                    il tasto SCARICA, e all'interno troverà le coordinate
                    bancarie, oppure può andare alla sezione Termini di
                    pagamento dove incontrerà il nostro IBAN. <br/> Nel caso in cui la fattura non sia presente, le verrà inviata
                    una mail all’indirizzo di posta elettronica indicato nella sezione: “Dati legali delle parti”, contenente la
                    stessa entro 24 ore dalla ricezione del pagamento.<br/>
                    <br/> - La fattura mensile relativa all’erogazione dei
                    servizi contabili sopra citati è da pagare al momento
                    della ricezione della stessa o alla sua scadenza, in una
                    sola fase, al 100%, utilizzando il servizio Paypal,
                    attraverso bonifico bancario, o con pagamento
                    ricorrente.

                    <div id="payment"></div>
                    <h1 className="payment"> Termini di pagamento</h1>
                    <p>{props.customer.companyName} è tenuto a pagare il servizio
                        sopraindicato, al momento dell'accettazione del
                        seguente contratto. Attraverso Paypal all'indirizzo
                        web buy.lobesoft.com o tramite bonifico bancario ai
                        dati sottoindicati: <br/></p>

                    <br/><strong>Bonifico bancario a:</strong><br/>
                    Nome banca: Bank of Georgia<br/>
                    Indirizzo banca: 2 Leonidze Street, Tbilisi,
                    Georgia<br/>
                    Beneficiario: LOBESOFT LLC<br/>
                    IBAN: GE65BG0000000131436677<br/>
                    Swift code: BAGAGE22 XXX

                    <div id="warranty"></div>
                    <h1 className="warranty"> Garanzia</h1>
                    <p>Lobesoft LLC garantisce che i servizi
                        verranno eseguiti in modo professionale. Il
                        cliente deve informare per iscritto Lobesoft
                        LLC di qualsiasi violazione della presente
                        garanzia entro 30 giorni dalla consegna dei
                        servizi. Nella misura consentita dalla
                        legge, il solo ed esclusivo rimedio per la
                        violazione della presente garanzia sarà la
                        riesecuzione o modifica del relativo
                        servizio.</p>


                    <h2>Garanzia del cliente</h2>
                    {props.customer.companyName} garantisce che: (i) ha
                    l'autorità di accettare questi termini e il
                    finanziamento necessario per pagare i
                    servizi richiesti; (ii) ha il titolo o la
                    licenza o i diritti ad utilizzare o
                    modificare qualsiasi software o prodotto, in
                    caso Lobesoft LLC richieda di apportare
                    modifiche nell'ambito di tali servizi; (iii)
                    fornirà a Lobesoft LLC, in caso di
                    necessità, l'accesso al personal computer
                    per consentire a Lobesoft LLC di eseguire
                    tempestivamente i servizi.


                    <h2>Assunzioni ed esclusioni</h2>
                    <p>Lobesoft LLC presume
                        che {props.customer.companyName} rispetterà questi
                        requisiti, e prima dell'inizio dei
                        servizi
                        concordati. {props.customer.companyName} dovrà:<br/>
                        a) fornire tutte le risorse, le
                        informazioni e i requisiti di lavoro
                        necessari per questo impegno;<br/>
                        b) fornire accesso a locali e
                        sistemi (incluso l'accesso remoto),
                        come necessario per le prestazioni
                        dei servizi;<br/>
                        c) eseguire il backup e la convalida
                        dei dati da tutti i sistemi a cui è
                        possibile accedere durante e in
                        connessione con i servizi nel
                        presente documento; Lobesoft LLC non
                        si assume alcuna responsabilità per
                        la perdita o la corruzione dei dati;<br/>
                        d) riconoscere che le installazioni,
                        o la risoluzione degli incidenti in
                        relazione a qualsiasi hardware o
                        software diverso dal Sistema Bridge
                        di Lobesoft LLC è considerata al di
                        fuori dell'ambito dei servizi qui
                        previsti, salvo diverso accordo tra
                        le parti.<br/></p>

                    <div id="licence"></div>
                    <h1 className="licence"> Licenza d'uso</h1>
                    <p>In base all’osservanza dei termini e
                        delle condizioni del Contratto, Lobesoft
                        LLC concede a {props.customer.companyName} un diritto
                        e una licenza d’uso per il Sistema
                        Bridge non esclusivi, non trasferibili,
                        limitati e totalmente revocabili di
                        installare, accedere e utilizzare una
                        copia del Servizio per uso non
                        commerciale ed esclusivamente personale.<br/>
                        <br/>Questo Contratto è applicabile
                        anche a qualsiasi modifica,
                        aggiornamento o miglioramento
                        ottenibile in qualsiasi dei
                        servizi richiesti. IN
                        OTTEMPERANZA A E SENZA AVER
                        INTENZIONE DI LIMITARE ALCUNA
                        DISPOSIZIONE O PROTEZIONE
                        STABILITA NEL PRESENTE ATTO, È
                        ESPRESSAMENTE ED ESPLICITAMENTE
                        PROIBITO DUPLICARE, COPIARE O
                        RIPRODURRE IN QUALSIASI FORMA IL
                        SISTEMA BRIDGE, O ALTRO
                        CONTENUTO, IN QUALSIASI ALTRO
                        SERVER, O ALTRO, CON LO SCOPO DI
                        RIPRODURLO O DISTRIBUIRLO IN
                        QUALSIASI ALTRA FORMA. Tutti i
                        diritti non specificamente
                        garantiti da questo Contratto
                        sono di uso esclusivo di
                        Lobesoft LLC.
                        <br/>{props.customer.companyName} non è
                        autorizzato a causare o
                        permettere la vendita o
                        altri tipi di distribuzione
                        commerciale o sfruttamento
                        commerciale (come
                        noleggiare, autorizzare,
                        concedere, prestare,
                        promulgare, caricare,
                        scaricare, trasmettere, che
                        sia o meno a pagamento)
                        della licenza d’uso (o di
                        nessuna sua parte), senza
                        previo ed espresso consenso
                        scritto da parte di Lobesoft
                        LLC.</p>

                    <h2>Diritto di proprietà</h2>
                    <p>{props.customer.companyName} RICONOSCE E ACCETTA DI NON
                        POSSEDERE ALCUNA PROPRIETÀ, O INTERESSE
                        NELLA PROPRIETÀ, NEI CONFRONTI DELLA
                        LICENZA D’USO DEL SISTEMA BRIDGE, E
                        INOLTRE RICONOSCE E ACCETTA CHE TALI
                        DIRITTI SONO E SARANNO SEMPRE DI
                        PROPRIETÀ DI LOBESOFT LLC. Se non
                        espressamente concesso nel presente
                        contratto, Lobesoft LLC, detiene tutti i
                        diritti, il titolo e gli interessi del
                        Sistema Bridge e tutti i diritti
                        d'autore, e altri diritti di proprietà
                        intellettuale ivi associati (ivi
                        inclusi, a titolo esemplificativo, ogni
                        modifica, aggiornamento o miglioramento
                        incorporato nel servizio offerto e
                        qualsiasi documento relativo) sono di
                        proprietà di Lobesoft LLC.
                        <br/>Il presente Contratto non conferisce
                        a {props.customer.companyName} alcun titolo o
                        diritto di proprietà della licenza
                        d’uso per il Sistema Bridge, e non
                        deve essere interpretato come un
                        diritto di vendita o trasferimento
                        di qualsiasi proprietà intellettuale
                        sulla licenza d’uso o relativa a
                        esso.</p>

                    <h2>Vendita</h2>
                    <p>Lobesoft LLC non riconosce il
                        trasferimento del Sistema Bridge,
                        compreso il trasferimento separato di
                        eventuali componenti del Sistema.
                        Pertanto, l’utente non è autorizzato a
                        dare in concessione, acquistare,
                        vendere, contrattare, barattare,
                        permutare, distribuire, scambiare,
                        mettere in vendita, autorizzare o
                        svendere i diritti, le responsabilità o
                        gli obblighi in virtù del Contratto, sia
                        nella sua integrità sia in parte, senza
                        previo consenso scritto da parte di
                        Lobesoft LLC. Ogni tentativo di fare ciò
                        non sarà valido e sarà considerato privo
                        di valore. </p>

                    <div id="general condition"></div>
                    <h1 className="general condition"> Condizioni
                        generali </h1>
                    <p>{props.customer.companyName} accetta di utilizzare il
                        Sistema Bridge, o qualsiasi parte di
                        esso, in modo conforme al presente
                        Contratto. {props.customer.companyName} NON È
                        AUTORIZZATO A: <br/>
                        (a) utilizzare a scopo commerciale
                        la licenza d’uso, o qualsiasi parte
                        di questo contratto;
                        (b) utilizzare la licenza d’uso o
                        permettere l’uso della licenza su
                        più di n° 1 computer se non
                        espressamente autorizzato da
                        Lobesoft LLC;<br/>
                        (c) utilizzare il Servizio o
                        permettere l’uso di tale licenza, o
                        rendere la licenza disponibile per
                        l’uso in rete, in disposizione
                        multi-utente o in accesso
                        remoto;<br/>
                        (d) vendere, noleggiare, dare a
                        noleggio, dare in concessione,
                        distribuire o altrimenti trasferire
                        la licenza o eventuali sue
                        copie;<br/>
                        (e) decodificare, derivare il codice
                        sorgente, modificare, decompilare,
                        deassemblare, copiare o creare
                        prodotti derivati della licenza
                        d’uso, in parte o per intero;<br/>
                        (f) rimuovere, disabilitare o
                        aggirare qualsiasi misura di
                        protezione, avviso di proprietà o le
                        etichette depositate presenti nel
                        Servizio;<br/>
                        (g) esportare o riesportare la
                        licenza o qualsiasi copia o
                        adattamento in violazione di
                        qualsiasi legge o regolamentazione
                        in vigore;<br/>
                        (h) creare dati o programmi
                        eseguibili che imitino dati o
                        funzionalità presenti nel
                        servizio.<br/>
                        <br/>Se si commette qualsiasi
                        violazione del presente
                        Contratto, il diritto di
                        utilizzare il Servizio e la
                        licenza in questo contratto
                        terminerà automaticamente, senza
                        alcun preavviso.

                        <br/>La violazione di questa
                        sezione da parte
                        di {props.customer.companyName} costituisce
                        una violazione materiale del
                        presente Contratto e/o dei
                        diritti d’autore applicabili
                        e di altri leggi e trattati
                        circa i diritti di proprietà
                        intellettuale, e
                        rende {props.customer.companyName} soggetto
                        a responsabilità civile e
                        penale.
                        Inoltre, {props.customer.companyName} s’impegna
                        a leggere e rispettare le
                        istruzioni di manutenzione e
                        le informazioni sulla
                        sicurezza o altre note
                        importanti presenti nel
                        manuale o nella
                        documentazione relativa al
                        Servizio.</p>

                    <h2>Obblighi di riservatezza</h2>
                    <p>Le parti devono mantenere la riservatezza
                        delle informazioni, nessuna delle Parti
                        utilizzerà in alcun modo, direttamente o
                        indirettamente, o impiegherà in altro
                        modo tutte o parte delle Informazioni
                        riservate dell'altra Parte per scopi
                        diversi dall'esecuzione ai sensi del
                        presente Contratto. Questo obbligo di
                        riservatezza sopravvivrà per 3 anni dopo
                        la risoluzione o scadenza del presente
                        Contratto.
                        {props.customer.companyName} riconosce che, se
                        fornisce suggerimenti o feedback a
                        Lobesoft LLC, lo fa volontariamente e
                        senza alcun obbligo di fiducia su
                        Lobesoft LLC in relazione a come avrà
                        diritto a utilizzare qualsiasi
                        suggerimento o feedback, in qualsiasi
                        modo e per qualsiasi scopo.</p>

                    <h2>Consenso sull'uso dei dati</h2>
                    <p> {props.customer.companyName} accetta che Lobesoft LLC
                        può raccogliere e utilizzare le
                        informazioni tecniche raccolte
                        nell'ambito dei servizi forniti.
                        Lobesoft LLC può utilizzare queste
                        informazioni esclusivamente per
                        migliorare il software, fornire servizi
                        personalizzati o tecnologie e non
                        divulgheranno queste informazioni in una
                        forma che identifica
                        personalmente {props.customer.companyName} </p>

                    <h2>Partnership</h2>
                    <p>Nessuna disposizione del presente
                        Contratto è intesa a costituire una
                        relazione fiduciaria, un'agenzia, una
                        joint venture, una partnership o la
                        fiducia tra le Parti e nessuna delle
                        Parti ha il potere di vincolare l'altra
                        Parte.</p>

                    <div id="legal condition"></div>
                    <h1 className="legal condition"> Condizioni
                        legali</h1>
                    <h2>Forza maggiore</h2>
                    <p>"Forza maggiore" indica il verificarsi di
                        un evento o circostanza che impedisce, o
                        impedisce a una parte, di eseguire uno o
                        più dei suoi obblighi contrattuali, ai
                        sensi del presente Contratto, se e nella
                        misura in cui la parte interessata
                        dall’impedimento dimostra: <br/>
                        <br/>a) che tale impedimento è al di
                        fuori del suo ragionevole
                        controllo; e

                        <br/>b) che non poteva
                        ragionevolmente essere
                        previsto al momento della
                        conclusione del contratto; e

                        <br/>c) che gli effetti
                        dell'impedimento non
                        potevano ragionevolmente
                        essere evitati o
                        superati dalla parte
                        interessata.

                        <br/>Qualora si verifichi
                        un evento di forza
                        maggiore, la parte
                        che ne subisce le
                        conseguenze dovrà
                        immediatamente
                        informare l’altra
                        parte dell’evento,
                        fornendo la prova
                        che quest’ultimo
                        corrisponde a quanto
                        previsto nel
                        contratto.

                        <br/>Le circostanze
                        di forza
                        maggiore
                        interrompono
                        l'esecuzione del
                        contratto e le
                        parti si
                        incontreranno
                        allo scopo di
                        definire le
                        condizioni del
                        loro rapporto.

                        <br/>Se le
                        circostanze
                        di forza
                        maggiore
                        durano più
                        di 60
                        giorni, il
                        presente
                        contratto si
                        risolverà
                        automaticamente,
                        a meno che
                        le parti non
                        concludano
                        un altro
                        accordo.
                        Forza
                        maggiore o
                        circostanze
                        impreviste
                        saranno
                        considerate
                        quelle
                        circostanze
                        espressamente
                        specificate
                        dalla Corte
                        di
                        Amsterdam.<br/>
                    </p>

                    <h2>Integrità</h2>
                    <p> Il presente contratto esprimerà
                        l'aggregazione delle passività delle
                        parti.
                        Qualsiasi condizione generale o
                        speciale, che appare nei documenti,
                        inviata o trasferita dalle parti non può
                        essere inclusa nel contratto. Salvo
                        un'eccezione, il contratto può essere
                        modificato solo mediante un accordo
                        supplementare, debitamente firmato dai
                        rappresentanti autorizzati del cliente e
                        Lobesoft LLC. </p>


                    <h2>Nullità</h2>
                    <p> Se una o alcune disposizioni del
                        presente contratto sono considerate
                        nulle o dichiarate nulle ai sensi di una
                        determinata legge, disposizione o
                        decisione finale di un ente giudiziario
                        competente, le altre disposizioni
                        rimarranno valide e significative. </p>
            </div>
            <h2>Sincerità</h2>
            <p> Il presente contratto è regolato dalla
                legge georgiana per i clienti residenti
                in Georgia e riguarda sia le norme
                principali sia quelle formali. La sede
                principale è la Corte di Tbilisi. <br/>
                <br/> Il contratto è regolato dalla
                legge olandese per i clienti
                residenti nel resto del mondo
                (ad eccezione della sopra
                indicata Georgia - Caucasica) e
                riguarda sia le norme principali
                sia quelle formali. La sede
                principale è la Corte di
                Amsterdam.</p>

            <h2>Assegnazione</h2>
            <p>Nessuna delle parti può cedere il
                presente Accordo senza il previo
                consenso scritto dell'altra parte, salvo
                preavviso scritto di un cambio di
                controllo o come diversamente
                specificato nel presente documento.</p>

            <div id="contract end"></div>
            <h1 className="contract end"> Termine del
                contratto </h1>
            <p>Ciascuna parte può recedere dal presente
                contratto in qualsiasi momento, dandone
                un preavviso scritto di 30 (trenta)
                giorni, che dovrà essere inviato
                all'indirizzo email indicato nella
                parte: “Dati legali delle parti”.</p>

            <h2>Risoluzione delle controversie</h2>
            <p> Qualsiasi controversia dovrebbe cercare
                di essere risolta amichevolmente dalle
                parti e, se non è possibile trovare una
                risoluzione entro 60 giorni, le parti
                convengono che un arbitro indipendente
                deve ascoltare e risolvere la
                controversia, che sarà vincolante e non
                soggetta a ricorso.</p>

            <div id="legal data"></div>
            <h1 className="legal data"> Dati legali
                delle parti </h1>

            <br/><strong> Fornitore </strong>

            Ragione sociale: Lobesoft LLC<br/>
            Indirizzo: M. Gakhokidze str., N 49,
            Samgori District, Tbilisi,
            Georgia<br/>
            Telefono: +1 786 453 0818<br/>
            E-mail: info@lobesoft.com<br/>
            Responsabile: Cristian Pietrobon<br/>

            <br/><strong> Cliente </strong>

            Ragione sociale: {props.customer.companyName}
            <br/>
            P.IVA: {props.customer.companyID} <br/>
            Indirizzo: {props.customer.address}
            <br/>
            Telefono: {props.customer.phone}
            <br/>
            E-mail: {props.customer.email}
            <br/>
            Responsabile: {props.customer.name} {props.customer.surname}
            <br/>

            <div
                id="Notice"></div>
            <h1 className="notice"> Avviso </h1>
            <p> Qualsiasi
                comunicazione
                fornita
                ai sensi
                del
                presente
                Accordo
                deve
                essere
                scritta
                per
                e-mail,
                con
                oggetto:
                Avviso
                urgente {props.customer.companyName},
                all’indirizzo
                info@lobesoft.com
                e sarà
                efficace
                (i) una
                volta
                ricevuto
                dalla
                Parte o
                rifiutato
                dalla
                Parte o
                (ii) il
                giorno
                lavorativo
                successivo. <br/>
            </p>

            <p> Tbilisi,
                il {date} </p>
            <br/>Firma
            Lobesoft
            LLC<br/>
            <br/> Cristian
            Pietrobon
            -
            CEO
            <div
                id="firma lobesoft">
                <img className='signature' src={firma}/>
            </div>
            <br/>Firma {props.customer.companyName}
            <br/>
            <br/> {props.customer.name} {props.customer.surname} -
            {props.customer.role}
            <br/>
            <strong>ACCETTATO
                ELETTRONICAMENTE
                DA {props.customer.name} {props.customer.surname}, {props.customer.companyName}</strong>
        </div>


)
};
