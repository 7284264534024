import React, {useState} from 'react';
import ReactDOM from 'react-dom';

export interface formBuilder {
    data:formData,
    onSubmit:Function,
    error:string | undefined,
}

export interface formData {
    sections: Array<formSection>
    style?:string
}

export interface formField {
    type:string,
    label?:string,
    name:string,
    optional?:boolean
}

export interface formSection {
    title:string,
    fields:formField[]
}

export const FormBuilder:React.FC<formBuilder> = (props:formBuilder) => {

    const [formValue,setFormValue] = useState({} as any);

    const [formErrors,setFormErrors] = useState({} as any);

    let onChanges = (event:any,field:formField) => {
        console.log(event.target.value);
        let value = event.target.value;
        let key = field.name;
        let newformValue = {...formValue};
        newformValue[key] = value;
        setFormValue(newformValue);
        if(formErrors[key]) {
            const newErrors = {...formErrors};
            delete newErrors[key];
            setFormErrors(newErrors);
        };
        console.log(formValue);
    };

    let validateForm = () => {
        let newErrors:any = {};
      for(let section of props.data.sections){
          for(let field of section.fields) {
              if(!field.optional) {
                  if(!(field.name in formValue) || formValue[field.name] === ''){
                      console.log('ERRORs',formErrors);
                       newErrors = {...newErrors};
                       newErrors[field.name] = true;
                      console.log('SET ERROR',newErrors);
                  }
              }
          }
      }
      console.log('FORM ERRORS',newErrors);
        setFormErrors(newErrors);
        console.log('FORM IS VALID',Object.keys(newErrors).length <= 0);
      return Object.keys(newErrors).length <= 0;
    };

    let onSend = () => {
        if(validateForm()) {
            props.onSubmit(formValue);
        }
    };



    return (
        <React.Fragment>
            <form className={props.data.style}>
                {props.data.sections.map((section,index) => {
                    return (
                        <div className='section'>
                            {index === 0 &&
                            <h1 className='titleBar'>
                                Registrazione
                            </h1>
                            }
                            <h2>{section.title}</h2>
                            {section.fields.map((field,index) => {
                                console.log(field);
                                return (
                                    <div className='field'>
                                        <div className='flex'>
                                            <label>{field.label}</label>{formErrors[field.name] && <span className='formError'>Inserisci un dato valido</span>}
                                        </div>
                                        <input type={field.type} onChange={(event) => {onChanges(event,field)}}/>
                                    </div>
                                )
                            })}
                            {props.error && index === props.data.sections.length-1 &&
                             <p className='formError wide'>{props.error}</p>
                            }
                            {index === props.data.sections.length-1 &&
                            <div className='flex wide btnBar justCon-cen'>
                                <div className='buttonBlue wide' onClick={onSend}>CONTINUA</div>
                            </div>
                            }
                        </div>
                    )
                })}
            </form>
        </React.Fragment>
    )
};